<template>
  <div class="app-s-container">
    <div class="page-outter">
      <div align="center">
        <img
          class="app-s-title"
          v-lazy="require('../../../assets/images/title13.png')"
        />
        <div class="app-s-d1">
          <div
            class="p-item"
            v-for="(item,index) in tds"
            :key="index"
          >
            <img
              class="m1"
              v-lazy="item.icon"
            />
            <span
              class="s1"
              v-html="item.name"
            ></span>
            <span
              class="s2"
              v-html="item.remark"
            >
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ApplicationScenario-ch",
  data() {
    return {
      tds: [
        {
          icon: require("../../../assets/images/app-icon31.png"),
          name: "个人健康管理",
          remark: `
                  可实现在线健康数据采集</br>
                  实时监测大数据健康评估
          `,
        },
        {
          icon: require("../../../assets/images/app-icon32.png"),
          name: "在线诊疗服务",
          remark: `
                  在线对接专业医师，支持图文</br>
                  电话、视频问诊多种形式</br>
                  实时高效问诊交流  
          `,
        },
        {
          icon: require("../../../assets/images/app-icon33.png"),
          name: "名医会诊服务",
          remark: `
                  通过平台汇聚优质医生资源</br>
                  实现异地在线会诊，满足用户</br>
                  更好层次医疗服务需求 
          `,
        },
        {
          icon: require("../../../assets/images/app-icon34.png"),
          name: "电子病历服务",
          remark: `
                  患者病历信息数字化，与医院</br>
                  等多方系统数据共享，提高</br>
                  就医便利性及医生随访 
          `,
        },
        {
          icon: require("../../../assets/images/app-icon35.png"),
          name: "医药电商服务",
          remark: `
                  连接药品零售终端，支持</br>
                  患者在线购药、就近配送 
          `,
        },
        {
          icon: require("../../../assets/images/app-icon36.png"),
          name: "家庭医生服务",
          remark: `
                  搭建家庭医生与签约用户</br>
                  服务互动平台，在线提供</br>
                  健康咨询、慢性病随访等服务
          `,
        },
        {
          icon: require("../../../assets/images/app-icon37.png"),
          name: "社区健康服务",
          remark: `
                  以平台实现优质医疗资源</br>
                  对社区的深入，整合社区医院</br>
                  资源，提升终端医疗服务能力
          `,
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.app-s-container {
  width: 100%;
  background: #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.app-s-title {
  display: block;
  width: 7rem;
  margin-top: 2.4rem;
}
.app-s-d1 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-bottom: 2.4rem;
  margin-top: 1rem;
  .p-item {
    width: 190px;
    height: 240px;
    background: #f3f3f3;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    .m1 {
      width: 54px;
      margin-top: 1rem;
    }
    .s1 {
      display: block;
      font-size: 14px;
      color: #6aa5ff;
      margin-top: 0.1rem;
    }
    .s2 {
      display: block;
      font-size: 12px;
      color: #aaaaaa;
      line-height: 2;
      margin-top: 14px;
    }
  }
}
</style>